.wrap {


  h4 {
    font-size: 24px !important;
    font-weight: bold !important;
    color: #0F435A !important;
    margin-bottom: 20px;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;
    color: #0F435A;
    margin-left: 10px;
  }

  .mapBlock {
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
  }
}

.visualLink {
  font-weight: 800 !important;
  font-size: 12px !important;
  text-decoration-line: none !important;
  text-transform: none !important;
  color: #2D3C43 !important;
}

.content {
  width: 210px;
}

.visualDashBlockWrap {
  width: 290px;
  height: 140px;
  margin: 15px 11px 11px 11px;
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #CAD0D3;
  border-radius: 3px;
  background: #FFFFFF;


  h4 {
    font-size: 14px !important;
    font-weight: bold !important;
    color: #0F435A !important;
    margin-bottom: 10px;
  }

  .description {
    color: #7C90B1 !important;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .editButton {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.visualDashBlockWrap:hover {
  box-shadow: 0 4px 30px rgba(34, 37, 51, 0.2);
  transition: 0.3s;
}

.comments {
  font-size: 10px;
}
