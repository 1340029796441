.modalWrap {

  :global {
    .ant-modal {
      width: 400px !important;
      height: 260px !important;
      //box-shadow: 0 0 30px rgba(0, 0, 0, 0.13);
      border-radius: 20px !important;
    }

    .ant-modal-header {
      border: none !important;
      border-radius: 20px !important;
      height: 50px !important;
    }

    .ant-modal-title {
      padding-top: 30px;
    }

    .ant-modal-content {
      text-align: center !important;
      border-radius: 20px !important;
    }
  }

  .modalTitle {
    font-weight: bold;
    font-size: 24px;
    color: #598253;
    margin-left: 15px;
    margin-top: 30px;
  }

  .modalSubTitleWrap {
    margin-top: 10px;

    .modalSubTitle {
      font-size: 14px;
      color: #7C90B1;
    }
  }

  .modalDescriptionWrap {
    margin-top: 25px;

    .modalDescription {
      font-weight: bold;
      font-size: 16px;
      color: #2D3C43;
    }
  }

  .modalButton {
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    .timePicker {
      width: 84px;
      margin-right: 5px;
    }

    .datePicker {
      width: 130px;
      margin-right: 5px;
    }

    .buttonTitle {
      font-weight: 700;
      margin: 0 10px;
    }
  }
}

