.SensorGraphsWrap {

}

.container {
  width: 100%;
}

.mapWrap {
}

.mapBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.headerTitle {
  margin-left: 8px;
}

.showAllGraphs {
  color: #00C09E !important;
  font-weight: bold;
  font-size: 16px;
  margin-right: 30px;
  cursor: pointer;
}

.f500GraphWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.f500GraphNodesWrap {
  margin-top: 12px;
  height: 570px;
  width: 300px;
  min-width: 250px;
  font-size: 12px;
  color: #2D3C43;
}

.f500GraphNodesTabletWrap {
  margin-top: 12px;
  height: 570px;
  width: 200px;
  min-width: 150px;
  font-size: 10px;
  color: #2D3C43;
}
