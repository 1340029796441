$green: #025230;
$light-green: #00b48d;
$orange: #F6A20A;
$red: #ff0000;
$yellow: #ffbf00;
$mint: #aad997;
$toxic-green: #9f3;
$blue: #3a89c9;
$light-blue: #9cc4e4;
$dark-blue: #0B2E59;

$label-color: #9B9689;
$border-color: #C8C3B5;
$gray: #f4f4f4;

$main-blue: #5d9fd3;

// ---------------
// Editor elements
// ---------------

$selected-element-border-color: #00c0ff;

$shape-border-color: #000;

$label-element-color: #000;