.sensorsImagesInputsWrap {
  max-width: 100%;
  max-height: 100%;
  margin-top: 15px;
  background: rgba(255, 255, 255, 0.7);

  .sensorsTitle {
    display: flex;

    .sensorsSub {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px;
      width: 55%;

      span {
        font-weight: bold;
        font-size: 24px;
        color: #071E26;
      }
    }

    .sensorsButtons {
      display: flex;
      justify-content: flex-end;
      width: 45%;
      padding-top: 20px;

      .button {
        padding: 20px 20px;
        cursor: pointer;
        width: 135px;
      }
    }
  }

  .container {
    margin: 0 auto;
    max-width: 1000px;
    padding: 15px;

    .driver {

      .cssBaseLine {
        margin-top: 15px;
        border-bottom: 1px solid rgba(124, 144, 177, 0.4);
      }

      span {
        font-weight: bold;
        font-size: 16px;
        color: #2D3C43;
      }
    }

    .blocksWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 25px;

      .inputWrapTitle {
        width: 120px;
      }

      @media screen and (max-width: 1140px) {
        justify-content: center;
      }
    }
  }
}

.selectImageWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 30px;
}

.selectImage {
  width: 40px;
  height: 30px;
  margin-right: 10px;
  text-align: center;
}

.image{
height: 100%;
}

.selectText {
  vertical-align:middle
}

.text {
  font-size: 13px;
  color: #2D3C43;
}

.inputWrapDegrees {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff !important;
      opacity: 1;
    }
  }
}



