.wrap {
  padding-top: 10px;

  .fullName {
    width: 130px;

    @media screen and (max-width: 1570px) {
      width: 120px;
    }
    @media screen and (max-width: 1420px) {
      width: 85px;
    }
    @media screen and (max-width: 1200px) {
      width: 80px;
    }
    @media screen and (max-width: 1060px) {
      width: 70px;
    }
    @media screen and (max-width: 980px) {
      width: 60px;
    }
    @media screen and (max-width: 800px) {
      width: 50px;
    }
  }

  .emailAddress {
    width: 160px;
    @media screen and (max-width: 1570px) {
      width: 150px;
    }
    @media screen and (max-width: 1420px) {
      width: 120px;
    }
    @media screen and (max-width: 1200px) {
      width: 115px;
    }
    @media screen and (max-width: 1060px) {
      width: 110px;
    }
    @media screen and (max-width: 980px) {
      width: 100px;
    }
    @media screen and (max-width: 800px) {
      width: 80px;
    }
  }

  .company {
    width: 80px;
    @media screen and (max-width: 1570px) {
      width: 70px;
    }
    @media screen and (max-width: 1420px) {
      width: 65px;
    }
    @media screen and (max-width: 1200px) {
      width: 60px;
    }
    @media screen and (max-width: 1060px) {
      width: 55px;
    }
    @media screen and (max-width: 980px) {
      width: 50px;
    }
    @media screen and (max-width: 800px) {
      width: 40px;
      font-size: 6px;
    }
  }

  .phoneNumber {
    width: 90px;
    @media screen and (max-width: 1570px) {
      width: 80px;
    }
    @media screen and (max-width: 1420px) {
      width: 50px;
    }
    @media screen and (max-width: 1200px) {
      width: 45px;
    }
    @media screen and (max-width: 1060px) {
      width: 45px;
    }
    @media screen and (max-width: 980px) {
      width: 45px;
    }
    @media screen and (max-width: 800px) {
      width: 45px;
    }
  }

  .activatedButton {
    color: #27AE60;

    .activated {
      margin-right: 5px;
    }
  }

  .buttonsGroup {
    width: 110px;

    @media screen and (max-width: 1060px) {
      width: 110px;
    }
    @media screen and (max-width: 980px) {
      width: 100px;
    }
    @media screen and (max-width: 800px) {
      width: 80px;
    }
  }
  .hasAccess {
    width: 50px;
    @media screen and (max-width: 1570px) {
      width: 50px;
    }
    @media screen and (max-width: 1420px) {
      width: 15px;
    }
    @media screen and (max-width: 1200px) {
      width: 15px;
    }
    @media screen and (max-width: 1060px) {
      width: 15px;
    }
    @media screen and (max-width: 980px) {
      width: 15px;
    }
    @media screen and (max-width: 800px) {
      width: 15px;
    }
  }

  .activationLink {
    width: 200px;
    font-size: 12px;

    .link {
      color: #2D3C43;
    }

    .hideLink {
      display: none;
    }

    @media screen and (max-width: 1570px) {
      width: 170px;
    }
    @media screen and (max-width: 1420px) {
      width: 135px;
    }
    @media screen and (max-width: 1200px) {
      width: 130px;
    }
    @media screen and (max-width: 1060px) {
      word-break: break-word;
      width: 110px;
      font-size: 10px;
    }
    @media screen and (max-width: 980px) {
      width: 100px;
    }
    @media screen and (max-width: 800px) {
      width: 100px;
      font-size: 6px;
    }

    .edit {
      color: #a9cc13;
    }

    .delete {
      color: #7C90B1;
    }
  }


  :global {
    .ant-table-row > td:first-child {
      color: #2D3C43 !important;
    }

    .ant-table-thead > tr > th {
      color: #7C90B1 !important;
      font-weight: bold !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #A9CC13;
    }
  }
}

.spin {
  margin: 20px 50% !important;
}
