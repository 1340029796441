.highcharts-container {
    overflow: visible !important;
}

.highcharts-tooltip span > span {
    background-color:rgba(255,255,255 1);
    padding: 2px 10px;
    border-radius: 2px;
}

#divContainerId .highcharts-container {
    z-index: 10 !important;
}
