.wrap {
  background-color: white;
  border: 1px solid #CAD0D3;
  border-radius: 3px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.title {
  color: #2D3C43;
  font-weight: bold;
}

.container {
  padding: 15px;
  max-height: 600px;
  overflow: auto;
  background-color: white;
}

.container::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9fd;
}

.container::-webkit-scrollbar-thumb {
  background-color: #7C90B1;
  border-radius: 10px;
}

.nodeBlockWrap {
  margin-bottom: 15px;
}

.checkboxBlock {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #A9CC13;
      border-radius: 5px;
    }

    .ant-checkbox-inner {
      border-radius: 5px;
    }
  }
}

.color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.checkboxNumber {
  display: flex;
}

.checkboxWrap {
  margin-right: 18px;
}