.footer {
  height: 40px;
  border-top: 1px solid #94A0B7;;
  font-size: 10px;
  text-transform: capitalize;
  color: #7C90B1;
  opacity: 0.5;

  .copyright {
    padding: 13px 0;
    font-size: 10px;
  }

  :global {
    .ant-select {
      font-size: 12px;
      padding-top: 3px;
    }
  }
}
