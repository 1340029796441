
.tablet {
  position: fixed !important;
  z-index: 10000 !important;
}

.sidebar {
  position: fixed !important;
  z-index: 10000 !important;
}


.logo {
  padding: 20px;
  margin-bottom: 13px;

  .largeImg {
    max-width: 146px;
    max-height: 20px;
    transition: all 0.2s;
  }

  .smallImg {
    width: 20px;
    height: 20px;
  }
}

.avatar {
  padding-left: 20px;
}

.profileInfo {
  font-size: 20px !important;
  margin-bottom: 20px;

  .profileInfoColumn {
    font-size: 14px !important;
  }

}

.smallSubmenu {
  padding-left: 10px !important;
}

.menuWrap {
  height: 85%;
}

.cssBaseLine {
  color: white;
  border-bottom: 1px solid #94A0B7;
  width: 100%;
}

.marBottom {
  margin-bottom: 30px;
  @media screen and (max-height: 750px) {
    margin-bottom: 0;
  }
}

.marTop {
  margin-bottom: 30px;
  @media screen and (max-height: 750px) {
    margin-bottom: 0;
  }
}

.trigger {
  font-size: 27px;
  color: #026F5B !important;
  position: absolute;
  right: -20px;
  top: 80px;
}

.setting {
  font-weight: 600;
  font-size: 12px;
  text-transform: capitalize;
  color: #7C90B1 !important;
}

.settingLink {
  position: absolute !important;
  bottom: 130px !important;
  width: 100%;
}

.modify {
  position: absolute !important;
  bottom: 160px !important;
  width: 100%;
  color: #7C90B1;
}

.visual {
  background: #014B3E;
  position: absolute !important;
  bottom: 200px !important;
}

.sizeVisualBig {
  width: 100%;
  transition: width 0.2s !important;
}

.sizeVisualSmall {
  width: 72px !important;
  transition: width 0.2s !important;
}

@media screen and (max-height: 800px) {
  .settingLink,
  .modify,
  .visual {
    position: inherit !important;
    bottom: 0 !important;
  }
  :global {
    .ant-menu-item a::before {
      position: relative !important;
    }
  }
}

:global {
  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark {
    position: relative;
    height: 100%;
  }

}

.sideTitle {
  color: white;
}

:global {
  .ant-layout-sider .ant-menu-dark .ant-menu-item > span > a {
    color: inherit;
  }

  .ant-layout-sider .ant-menu-dark .ant-menu-item {
    color: #7C90B1;
  }

  .ant-layout-sider .ant-menu-dark .ant-menu-item:hover {
    color: white !important;
  }
}

.lineBottom {
  color: white;
  border-bottom: 1px solid #94A0B7;
  margin-top: 35px;
  width: 100%;

  @media screen and (max-height: 750px) {
    margin-top: 0;
  }
}

.userSetting {
  :global {
    //.ant-menu-title-content {
    //  color: #A9CC13 !important;
    //}
    //.ant-menu-inline-collapsed-noicon {
    //  color: #A9CC13 !important;
    //}
    .ant-menu-submenu, .ant-menu-submenu-vertical, .ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after {
      background: #A9CC13 !important;
    }
  }

  font-size: 20px;
}

