.wrap {

  .container {
    cursor: pointer;
    width: 100px;
    height: 100px;
    background: #FFFFFF;
    border: 1px solid #CAD0D3;
    border-radius: 3px;
    text-align: center;
    margin-top: 15px;
    margin-right: 55px;

    .icon {
      color: #7C90B1;
      font-size: 30px;

    }

    .text {
      font-weight: bold;
      padding: 0 15px;
      color: #7C90B1;
    }
  }
}

.container:hover {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  border: 1px solid #A9CC13;
}

.container:hover .icon svg {
  path {
    fill: #A9CC13;
    color: #00C09E;
  }
}

.container:hover .text {
  color: #A9CC13 !important;
}
