.wrap {
  margin-top: 120px;
  max-width: 600px;

  @media screen and (max-width: 700px), (max-height: 700px) {
    margin-top: 50px;
  }

  :global {
    .ant-typography + h2.ant-typography {
      margin-top: 0 !important;
    }
  }

  .title {
    font-weight: 300;
    font-size: 30px;
    line-height: 30px;
    color: white;

    @media screen and (max-width: 700px) {
      font-size: 12px;
      line-height: 10px;
    }
  }

  .subTitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #A9CC13;

    @media screen and (max-width: 700px) {
      font-size: 10px;
      line-height: 15px;
    }
  }
}

