.wrap {
  width: 100%;

  .container {
    padding: 150px 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .button {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 65px;
  }
}

.titlePage {
  margin: 50px 0 0 10%;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.captcha {
  margin: 0 0 15px 65px;
}