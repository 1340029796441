.addNotifyWrap {
  max-width: 100%;
  max-height: 100%;
  margin-top: 15px;
  background: rgba(255, 255, 255, 0.7);

  .container {
    margin: 0 auto;

    .description {
      display: flex;
      justify-content: center;
      padding: 50px 20px;

      span {
        font-size: 16px;
        color: #2D3C43;
      }
    }

    .groupInputs {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 40px;

      .blockWrap {
        width: 600px;
        font-size: 12px;
        font-weight: 500;
      }

      .checkboxWrap {
        width: 470px;
        margin-left: 110px;
        margin-right: 30px;
        font-size: 13px;
        font-weight: 500;

        .selectDevicesWrap {
          color: #7C90B1;
          margin-bottom: 40px;
        }

        .locationTitle {
          font-weight: bold;
          color: #2D3C43;
          margin-bottom: 10px;
        }

        .checkboxItem {
          color: #2D3C43;
          margin: 15px 0;
        }

        .noDevices {
          font-style: italic;
          font-weight: 500;
          color: #7C90B1;
        }

        .hasDevice {
          color: #2D3C43;
        }

        :global {
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #A9CC13;
          }

          .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: #A9CC13;
          }

          .ant-checkbox-group {
            //display: table-caption !important;
          }
        }

        @media screen and (max-width: 1340px) {
          margin-left: 160px;
        }
      }
    }
  }
}

