.headerBlock {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  background-image: linear-gradient(112.81deg, rgba(0, 61, 54, 0.8) 17.55%, rgba(78, 141, 68, 0.8) 90.03%), url('../../assets/img/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.auth {
  @media screen and (max-width: 700px), (max-height: 700px) {
    justify-content: center !important;
  }
}

.info {
  @media screen and (max-width: 700px), (max-height: 700px) {
    justify-content: normal !important;
    margin-top: 35px;
    padding: 0 10px;
  }
}

.content {
  margin-top: 150px;

  @media screen and (max-width: 700px), (max-height: 700px) {
    margin-top: 50px;
  }
}
