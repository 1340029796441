:global {
  h5.ant-typography, .ant-typography h5 {
    color: #7C90B1 !important;
    font-weight: 600;
    font-size: 10px !important;
  }

  h2.ant-typography, .ant-typography h2 {
    margin-top: 0 !important;
  }
}

.wrap {
  .container {
    display: flex;
    justify-content: space-between;

    .mapBlock {
      display: flex;
      flex-wrap: wrap;
      max-width: 90%;
    }
  }
}

.sitesBlockWrap {
  width: 452px;
  height: 195px;
  margin: 10px 8px;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #CAD0D3;
  border-radius: 3px;
  position: relative;


  .pic {
    width: 45%;
    padding: 27px;

    .powerHold {
      width: 124px;
    }
  }

  .notify {
    margin: -20px 0 0 105px;
  }

  .content {
    width: 45%;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    flex-direction: column;
    padding: 25px 9px 0;
    color: #0F435A;

    h3 {
      color: #0F435A;
      font-size: 20px !important;
      height: 55px;
      overflow: hidden;
    }

    .cartAddress {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #7C90B1;
      height: 85px;
      overflow: hidden;
    }
  }

  .editButton {
    width: 10%;
    padding: 15px;
    color: #7C90B1;
  }
}

.editButtonIcon:hover svg {
  path {
    fill: #A9CC13;
    color: #00C09E;
  }
}

.sitesBlockWrap:hover {
  cursor: pointer;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
}

:global {
  .ant-tooltip-inner {
    min-height: 36px !important;
    border-radius: 8px !important;
  }
}

.suspendedBlockWrap {
  box-shadow: none !important;
  background: #E9EAEC;
  border: 1px solid #CAD0D3 !important;
  border-radius: 3px;
  cursor: default !important;
}

.selectedSite {
  border: 1px solid #00C09E !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
}
.flagIcon {
  background-image: url("../../assets/icons/flag.svg") !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 19px;
  height: 19px;
}

.hasAlarmedSensor {
  box-shadow: 0 0 12px rgba(241, 77, 73, 0.4) !important;
  background: linear-gradient(0deg, rgba(241, 77, 73, 0.1), rgba(241, 77, 73, 0.1)), #FFFFFF !important;
  border: 1px solid #F14D49 !important;
  border-radius: 3px !important;
}

.hasWarningSensor {
  background: linear-gradient(0deg, rgba(255, 185, 131, 0.2), rgba(255, 185, 131, 0.2)), #FFFFFF !important;
  border: 1px solid #F47E21;
  border-radius: 3px;
}


