@import "https://code.highcharts.com/css/stocktools/gui.css";
@import "https://code.highcharts.com/css/annotations/popup.css";


.widget {
  display: flex;
  flex-direction: column;
}


.widgetBody {
  padding: 12px;
  flex: 1 1 auto;
  margin-bottom: 50px;
}

.widgetLiveBody {
  padding: 12px;
  height: 400vh;
  flex: 1 1 auto;
  margin-bottom: 50px;
}

.deviceBlock {
}

.graphsBlockWrap {
  margin-bottom: 80px;
  overflow: visible !important;
}

.emptyWrap {
  margin-bottom: 80px;
  width: calc(100vw - 600px);
  min-width: 290px;
  overflow: visible !important;
  margin-top: 10px;

  @media screen and (max-width: 1000px), (max-height: 700px) {
    width: calc(100vw - 350px);
  }
}

.emptyCard {
  margin-top: 30px;
}
