.alert {
  :global {
    position: absolute !important;
    top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 30% !important;
    right: 30% !important;
    text-align: center !important;
    border-radius: 15px !important;
    background-color: white !important;
    border: 1px solid #B7EB18 !important;
  }
}

.userActivationAlert {
  :global {
    position: absolute !important;
    top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 30% !important;
    right: 30% !important;
    text-align: center !important;
    border-radius: 15px !important;
    background-color: rgba(245, 0, 7, 0.31) !important;
    border: 1px solid red !important;
  }
}
