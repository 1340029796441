.userAlert {
  :global {
    position: absolute !important;
    z-index: 100000;
    top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 30% !important;
    right: 30% !important;
    text-align: center !important;
    border-radius: 15px !important;
    //background-color: white !important;
    border: 1px solid #B7EB18 !important;


  }
}

.addUserWrap {

  .addUserInputsWrap {
    max-width: 100%;
    max-height: 100%;
    margin-top: 15px;
    background: rgba(255, 255, 255, 0.7);


    .container {
      margin: 0 auto;

      .groupInputs {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 40px;

        .blockWrap {
          width: 450px;

        }

        .siteAccessWrap {
          width: 470px;
          margin-left: 110px;
          margin-right: 30px;

          .titleBlock {
            margin-bottom: 65px;

            .title {
              font-size: 16px;
              font-weight: 700;
              color: #2D3C43;
            }
          }

          .subTitle {
            color: #2D3C43;
            width: 33.3%;
          }

          .accessBlock {
            display: flex;
            justify-content: space-between;
            height: 60px;
            margin-top: 25px;


            .description {
              color: #2D3C43;
              font-weight: 600;
              width: 33.3%;

              :global {
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: #A9CC13;
                }
              }
            }

            .titleName {
              padding-top: 5px;
            }

            .selectWrap {
              width: 33.3%;
            }

            .select {
              width: 120px;
            }
          }
        }
      }
    }
  }
  .buttonDelete {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}





