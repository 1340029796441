.block {
  display: flex;
  justify-content: flex-end;

  .titleWrap {
    min-width: 60px;
    padding: 5px 10px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .input {
    width: 340px;
  }
}