.block {
  display: flex;
  margin-right: 20px;

  @media screen and (max-width: 1430px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1500px) {
    margin-right: 0;
  }

  .title {
    padding: 5px 10px 0 0;
    @media screen and (max-width: 1430px) {
      padding: 10px 0;
    }
  }

  .input {
    width: 270px;
  }
}
