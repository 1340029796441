.sensorsNamesInputsWrap {
  max-width: 100%;
  max-height: 100%;
  margin-top: 15px;
  background: rgba(255, 255, 255, 0.7);

  .sensorsTitle {
    display: flex;

    .sensorsSub {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px;
      width: 55%;

      span {
        font-weight: bold;
        font-size: 24px;
        color: #071E26;
      }
    }

    .sensorsButtons {
      display: flex;
      justify-content: flex-end;
      width: 45%;

      .import {
        margin: 35px 0 0 0;
        cursor: pointer;
      }

      .export {
        margin: 35px 25px 0 25px;
        cursor: pointer;
      }
    }
  }

  .container {
    margin: 0 auto;
    max-width: 1000px;
    padding: 15px;

    .driver {

      .cssBaseLine {
        margin-top: 15px;
        border-bottom: 1px solid rgba(124, 144, 177, 0.4);
      }

      span {
        font-weight: bold;
        font-size: 16px;
        color: #2D3C43;
      }
    }

    .blocksWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 25px;

      @media screen and (max-width: 1140px) {
        justify-content: center;

        .sideBlock {
          margin-left: 12px;
        }
      }
    }


  }
}




