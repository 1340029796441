.footer {
  height: 40px;

  .connectUs {
    margin-left: 35px;
    font-weight: 600;
    font-size: 12px !important;
    text-transform: capitalize;
    color: #7C90B1 !important;

    .select {
      margin-left: 60px;
      color: #7C90B1 !important;
    }

    :global {
      @media screen and (max-width: 700px) {
        .ant-select-selection-item {
          font-size: 10px;
        }
      }
    }
  }

  .copyright {
    font-weight: 600;
    font-size: 10px;
    text-transform: capitalize;
    color: #7C90B1;
    opacity: 0.5;
    margin-top: 12px;
    margin-right: 25px;

    @media screen and (max-width: 700px) {
      font-size: 7px;
    }
  }

}

