.custom-node {
  position: relative; }
  .custom-node .image-wrapper {
    display: block;
    position: relative;
    height: 100%;
    width: 100%; }
    .custom-node .image-wrapper img {
      display: block;
      height: 100%;
      width: 100%; }
    .custom-node .image-wrapper:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block; }
  .custom-node .srd-port {
    border: 1px solid crimson; }
  .custom-node .sensor-port {
    border: 1px solid #9f3;
    height: 17px;
    width: 17px;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer; }
    .custom-node .sensor-port .sensor-info {
      left: 8px;
      top: -8px;
      position: absolute;
      text-align: center;
      font-weight: bold;
      pointer-events: none;
      width: 200px;
      transform: translate(-50%, -100%); }
    .custom-node .sensor-port.running-state {
      position: absolute;
      z-index: 15;
      bottom: -55px;
      left: 50%;
      height: 22px;
      width: 100%;
      min-width: 250px;
      background-color: #fff;
      white-space: nowrap;
      overflow: hidden;
      padding: 2px 2px;
      text-align: center;
      transform: translateX(-50%); }
      .custom-node .sensor-port.running-state.not-selected {
        opacity: 0.5; }
    .custom-node .sensor-port.filled {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #025230; }
  .custom-node .drag-box {
    position: absolute;
    right: 0;
    bottom: 0; }
    .custom-node .drag-box img {
      display: block;
      height: 10px;
      width: 10px; }
  .custom-node .flip-icon {
    position: absolute;
    top: 4px;
    left: 0;
    border: none;
    background: transparent;
    display: block; }
    .custom-node .flip-icon img {
      display: block;
      height: 15px;
      width: 15px; }
  .custom-node .remove-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: 0;
    font-size: 25px;
    background-color: transparent;
    font-weight: bold;
    line-height: 20px;
    z-index: 1;
    cursor: pointer; }
  .custom-node .machine-name {
    width: 100%;
    display: block;
    margin-top: 5px;
    min-width: 250px; }
  .custom-node .sensor-tooltip {
    text-align: center;
    color: #ffffff;
    background-color: #000;
    width: 150px;
    padding: 5px 8px;
    border-radius: 3px;
    position: absolute;
    display: none;
    font-size: 13px; }
    .custom-node .sensor-tooltip::after {
      content: '';
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background-color: #000;
      position: absolute;
      bottom: -5px;
      left: calc(50% - 5px);
      z-index: -1; }
    .custom-node .sensor-tooltip::before {
      content: '';
      height: 5px;
      width: 100%;
      position: absolute;
      bottom: -5px;
      left: 0;
      display: block; }
  .custom-node .sensor-connector-port:hover + .sensor-tooltip {
    display: block; }
  .custom-node .sensor-port:hover + .sensor-tooltip, .custom-node .sensor-tooltip:hover {
    display: block;
    cursor: pointer; }
