:global {
  h5.ant-typography, .ant-typography h5 {
    color: #7C90B1 !important;
    font-weight: 600;
    font-size: 10px !important;
  }

  h2.ant-typography, .ant-typography h2 {
    margin-top: 0 !important;
  }
}

.wrap {
  .subtitle {
    font-weight: bold;
    font-size: 16px;
    color: #0F435A;
    margin-left: 10px;
  }

  .mapBlock {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;

    a {
      text-decoration-line: none !important;
      text-transform: none !important;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }
}

.mapWrap {
  width: 90%;
}

.OEMCursor {
  cursor: pointer !important;
}

.devicesBlockWrap {
  width: 290px;
  height: 140px;
  margin: 12px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #CAD0D3;
  border-radius: 3px;
  background: #FFFFFF;
  cursor: pointer;

  .pic {
    width: 40%;
    padding: 30px 0 0 30px;

    .devicesPic {
      width: 70px;
    }
  }

  .notify {
    margin: -35px 0 0 50px;
    width: 30px;
  }

  .activation {
    width: 45%;
    padding: 30px 5px 0 5px;

    img {
      margin-right: 10px;
    }

    h4 {
      font-size: 14px !important;
      font-weight: bold !important;
      color: #0F435A !important;
      margin-top: 15px;
    }

    .active {
      color: #27AE60 !important;
      font-size: 12px;
    }

    .suspended {
      color: #7C90B1 !important;
      font-size: 12px;
      font-weight: bold;
    }

    .offline {
      font-size: 12px;
      color: #7C90B1 !important;
    }

    .titleContainer {
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      font-size: 14px;
      color: #0F435A;
      overflow: hidden;
    }

    .title {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .editButton {
    padding: 15px 12px 0 5px;
    width: 15%;
  }
}

.editButtonIcon:hover svg {
  cursor: pointer;
  path {
    fill: #A9CC13;
    color: #00C09E;
  }
}

.suspendedBlockWrap {
  box-shadow: none !important;
  background: #E9EAEC;
  border: 1px solid #CAD0D3 !important;
  border-radius: 3px;
  cursor: default;
}

.offlineBlockWrap {
  border: 1px solid #F14D49;
  box-shadow: 0 0 12px rgba(241, 77, 73, 0.4);
  border-radius: 3px;
}

.devicesBlockWrap:hover {
  //cursor: pointer;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
}

.selectedDevice {
  border: 1px solid #00C09E !important;
  box-shadow: none;
  transition: 0.3s;
  position: relative;
}

.flagIcon {
  background-image: url("../../assets/icons/flag.svg") !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 19px;
  height: 19px;
}

.hasAlarmedSensor {
  box-shadow: 0 0 12px rgba(241, 77, 73, 0.4) !important;
  background: linear-gradient(0deg, rgba(241, 77, 73, 0.1), rgba(241, 77, 73, 0.1)), #FFFFFF !important;
  border: 1px solid #F14D49 !important;
  border-radius: 3px !important;
}

.hasWarningSensor {
  background: linear-gradient(0deg, rgba(255, 185, 131, 0.2), rgba(255, 185, 131, 0.2)), #FFFFFF !important;
  border: 1px solid #F47E21 !important;
  border-radius: 3px !important;
}


