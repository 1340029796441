.inputTitle {
  font-size: 16px;
  color: #2D3C43;
  margin: 35px 0 5px 0 !important;
  @media screen and (max-width: 700px), (max-height: 700px) {
    margin: 0 0 3px 0 !important;
    font-size: 12px;
  }
}

.loginInput {
  height: 40px;
  @media screen and (max-width: 700px), (max-height: 700px) {
    height: 25px !important;
  }
}

.button {
  @media screen and (max-width: 700px), (max-height: 700px) {
    height: 30px !important;
    font-size: 12px;
  }
}

.captcha {
  transform: scale(0.75);
  transform-origin: 0 0;
  width: 100%;
  max-width: 300px;
  margin: 0 0 5px 0px;
}

@media (max-width: 1800px) {
    .captcha {
        transform: scale(0.55);
        max-width: 250px;
    }
}

@media (max-width: 400px) {
    .captcha {
        transform: scale(0.50);
        max-width: 200px;
    }
}
