.machine {
  position: absolute;
  z-index: 2; }
  .machine.is-details {
    position: relative; }
  .machine.gray .image-wrapper {
    filter: grayscale(100%); }
  .machine.blinking {
    animation: blink-animation 1.5s infinite; }
  .machine .image-wrapper {
    background-size: cover; }
  .machine .anchor_point {
    height: 15px;
    width: 15px;
    border: 1px solid crimson; }
  .machine .sensor_point {
    height: 15px;
    width: 15px;
    border: 1px solid #aaa;
    background-color: #aaa;
    cursor: pointer; }
    .machine .sensor_point.filled {
      background-color: #333; }
    .machine .sensor_point.NA, .machine .sensor_point.OK {
      background-color: #00b48d;
      border-bottom: #00b48d; }
    .machine .sensor_point.WARNING {
      background-color: #ffbf00; }
    .machine .sensor_point.Alarm {
      background-color: #ff0000; }
    .machine .sensor_point .sensor-info {
      left: 8px;
      top: -8px;
      position: absolute;
      text-align: center;
      font-weight: bold;
      pointer-events: none;
      width: 200px;
      transform: translate(-50%, -100%);
      color: #333; }
  .machine .sensor-tooltip {
    text-align: center;
    color: #ffffff;
    background-color: #000;
    width: 150px;
    padding: 5px 8px;
    border-radius: 3px;
    position: absolute;
    display: none;
    font-size: 13px; }
    .machine .sensor-tooltip::after {
      content: '';
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background-color: #000;
      position: absolute;
      bottom: -5px;
      left: calc(50% - 5px);
      z-index: -1; }
  .machine .sensor_point:hover + .sensor-tooltip {
    display: block; }
  .machine .flip-icon {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background: transparent;
    display: block;
    cursor: pointer; }
    .machine .flip-icon img {
      display: block;
      height: 15px;
      width: 15px; }
  .machine-name {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    width: 200px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }
