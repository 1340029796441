@import "variables";
@import "buttons";

//
//sup {
//  top: -0.5em;
//}
//sub, sup {
//  position: relative;
//  font-size: 75%;
//  line-height: 0;
//  vertical-align: baseline;
//}
//
///* HTML5 display-role reset for older browsers */
//article, aside, details, figcaption, figure,
//footer, header, hgroup, menu, nav, section {
//  display: block;
//}
//
//html, body {
//  height: 100%;
//
//  #root {
//    min-height: 100%;
//    display: flex;
//    flex-direction: column;
//
//    .fullscreen {
//      flex: 1 0 auto;
//    }
//  }
//}
//
//body {
//  line-height: 1;
//  font-family: 'Helvetica';
//  background-color: #e9f2f9;
//}
ol, ul {
  list-style: none;
}
//blockquote, q {
//  quotes: none;
//}
//blockquote:before, blockquote:after,
//q:before, q:after {
//  content: '';
//  content: none;
//}
//table {
//  border-collapse: collapse;
//  border-spacing: 0;
//  border: 1px solid $border-color;
//
//  thead {
//    th {
//      border: 1px solid $border-color;
//      background-color: #eeeeee;
//      padding: 10px;
//    }
//  }
//
//  tbody {
//    td {
//      border: 1px solid $border-color;
//      padding: 10px;
//      text-align: center;
//    }
//  }
//}

input {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

button {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.schema-tabs {
  display: flex;
  overflow: auto;
  border-radius: 5px;
}

.schema-tab {
  //background-color: $main-blue;
  border-bottom: $main-blue;
  padding-right: 15px;
  display: flex;
  color: #ffffff;
  font-weight: bold;
  align-items: center;
  border-radius: 0;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  &.dragging {
    opacity: 0.2;
  }

  &.selected {
    //background-color: $light-blue;
    border-bottom: $light-blue;
  }

  &__icon {
    display: block;
    margin: 0 5px 0 15px;
    flex: 0 0 auto;
    height: 12px;
    width: 12px;
  }

  &__button {
    margin-left: 5px;
    border: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }

  input {
    border: none;
    margin: 10px 0;
    color: black;
  }

  &__body {
    display: flex;
    align-items: center;
    height: 100%;

    .title {
      text-decoration: none;
      color: #fff;
      font-size: 13px;
      line-height: 33px;
    }

    & > a {
      background: transparent;
      border: none;
      padding-left: 32px;
      margin-left: -32px;
      cursor: pointer;
      height: 100%;

      img {
        height: 15px;
      }
    }
  }
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
  font-weight: bold;
  line-height: 1;
  color: $border-color;
  border: none;
  background: transparent;
}

@keyframes blink-animation {
  0% {
    opacity: .3;
  }
  50% {
    opacity: 1;
  }
  100%{
    opacity: .3;
  }
}