header {
  background-color: #0B2E59;
  position: relative; }
  header .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  header .logo-img {
    display: flex;
    align-items: center;
    margin-right: 10px; }
    header .logo-img img {
      height: 17px; }
  header .simple-dropdown__trigger {
    padding: 0; }
  header .simple-dropdown .dropdown-value {
    min-width: 160px;
    list-style: none;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    overflow: hidden; }
    header .simple-dropdown .dropdown-value > * {
      display: flex;
      padding: 10px 20px;
      line-height: 20px;
      color: #333333;
      white-space: nowrap;
      border: 1px solid #C8C3B5;
      border-width: 0 0 1px 0;
      font-size: 13px;
      font-family: 'Helvetica';
      background: none;
      width: 100%;
      text-align: left;
      cursor: pointer;
      align-items: center; }
      header .simple-dropdown .dropdown-value > * svg {
        margin-right: 5px; }
      header .simple-dropdown .dropdown-value > *:last-child {
        border: none; }
      header .simple-dropdown .dropdown-value > *:hover {
        text-decoration: none;
        background-color: #9cc4e4; }
  header .nav-urls {
    display: flex; }
  header .nav-url {
    padding: 13px 15px 14px;
    font-size: 13px;
    color: #ffffff;
    line-height: 1.2;
    display: flex;
    align-items: center; }
    header .nav-url > svg {
      margin-left: 4px; }
    header .nav-url:hover {
      color: #fff;
      background-color: #5d9fd3;
      text-decoration: none; }

.banner-container {
  background-color: #9cc4e4;
  margin-top: -42px; }
  .banner-container .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 15px 50px; }
  .banner-container .banner {
    display: block; }
    @media (max-width: 1100px) {
      .banner-container .banner {
        display: none; } }

.top-menu {
  display: flex;
  padding: 0 25px; }
  @media (max-width: 768px) {
    .top-menu {
      overflow: auto; } }
  .top-menu li {
    border-radius: 5px;
    margin-right: 20px; }
    .top-menu li:hover, .top-menu li.active {
      background-color: #5d9fd3; }
    .top-menu li a {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 84px;
      width: 100px;
      padding-top: 5px;
      text-decoration: none; }
      .top-menu li a img {
        display: block;
        margin-bottom: 2px; }
      .top-menu li a div {
        font-size: 13px;
        color: #fff;
        text-align: center; }

#footer {
  margin-top: 20px; }
  #footer .container {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-bottom: 10px; }
    #footer .container .footer-links {
      display: flex;
      align-items: center; }
      #footer .container .footer-links li {
        margin-left: 15px; }
