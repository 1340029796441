:global {
  .ant-layout {
    background: #F9F9F9 !important;
  }

  .ant-layout-header {
    height: 60px !important;
    line-height: 30px !important;
  }

  .ant-layout-content {
    margin: 30px 25px 0 !important;
  }

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon {
    vertical-align: text-bottom !important;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    path {
      fill: white !important;
    }
  }

  .ant-layout-sider-children {
    padding: 5px !important;
  }

  .ant-menu-inline-collapsed {
    width: auto !important;
  }

  .components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  .ant-layout-sider-children {
    height: 100vh !important;
  }

  .ant-layout-sider,
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #003D36 !important;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #A9CC13 !important;
  }

  .ant-menu-submenu-title {
    color: white;
  }

  .ant-layout-footer {
    padding: 0 20px !important;
    background: white !important;
  }
}


.wrap {
  min-height: 100vh !important;
}

.tablet {
  margin-left: 80px;
}
