.avatar {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  background-color: #7C90B1;
  font-size: 17px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  color:  #F2F2F2;
  opacity: 0.5;
}
