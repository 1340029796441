.loading-component {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading-component_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 14px;
    max-width: 54px;
    padding: 0;
    margin: 0; }

@keyframes loading {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.4;
    margin: 0 4px; }
  25% {
    width: 13px;
    height: 13px;
    opacity: 0.7;
    margin: 0 3px; }
  50% {
    width: 14px;
    height: 14px;
    opacity: 1;
    margin: 0 2px; }
  75% {
    width: 13px;
    height: 13px;
    opacity: 0.7;
    margin: 0 3px; }
  100% {
    width: 10px;
    height: 10px;
    opacity: 0.4;
    margin: 0 4px; } }
  .loading-component_circle {
    width: 10px;
    height: 10px;
    opacity: 0.4;
    border-radius: 50%;
    background-color: #666666;
    margin: 0 4px; }
  .loading-component_circle-white {
    width: 10px;
    height: 10px;
    opacity: 0.4;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 4px; }
  .loading-component #loading-circle-first {
    animation: loading 0.6s linear 0s infinite; }
  .loading-component #loading-circle-second {
    animation: loading 0.6s linear 0.2s infinite; }
  .loading-component #loading-circle-last {
    animation: loading 0.6s linear 0.4s infinite; }
